<template>
    <section class="viviendas-ver-residente ml-4">
        <div class="row">
            <div class="col-12 col-lg-8 custom-scroll overflow-auto" style="height:calc(100vh - 57px)">
                <div class="row my-4">
                    <div class="col-12">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ name: 'viviendas.sistema' }">
                                Viviendas
                            </el-breadcrumb-item>
                            <el-breadcrumb-item :to="{ name: 'viviendas.residentes' }">
                                {{ residente.vivienda }}
                            </el-breadcrumb-item>
                            <el-breadcrumb-item v-if="residente.tipo == 5">
                                Propietario
                            </el-breadcrumb-item>
                            <el-breadcrumb-item v-else>
                                Residente
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <!-- info principal -->
                <div class="row">
                    <div class="col-12 col-sm-8 col-md-7 col-lg-9 col-xl-6">
                        <div class="row mt-3">
                            <div class="col-12 col-sm-auto">
                                <div class="circle-icon bg2 rounded-circle f-30 d-middle-center mr-3 mx-auto">
                                    <i class="icon-account-outline op-05" />
                                </div>
                            </div>
                            <div class="col-md pl-auto pl-sm-0">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="f-20 f-500">{{ residente.nombre }} {{ residente.apellido }}</p>
                                        <p class="f-14">{{ residentePropietario(residente) }}</p>
                                    </div>
                                    <div class="my-auto">
                                        <el-tooltip placement="bottom-start" effect="light" :visible-arrow="false"
                                                    popper-class="editar-residente br-4"
                                        >
                                            <div slot="content" class="tooltip-editar-residente">
                                                <div class="d-flex mb-2 editar-opcion py-1 px-1 cr-pointer br-4"
                                                     @click="editarResidente"
                                                >
                                                    <i class="icon-pencil-outline mr-2 f-17" />
                                                    <p class="f-15">Editar datos</p>
                                                </div>
                                                <div class="d-flex my-auto editar-opcion py-1 px-1 cr-pointer br-4"
                                                     @click="modalEliminarResidente"
                                                >
                                                    <i class="icon-delete-outline my-auto mr-2 f-17" />
                                                    <div>
                                                        <p class="f-15">Eliminar a {{ residente.nombre }}</p>
                                                        <p class="f-13 text-86">
                                                            {{ residente.nombre }} no podrá acceder
                                                            a Mi Conjunto y todo registro será borrado del sistema
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto">
                                                <i class="icon-vertical-points" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
                                        <div class="d-flex">
                                            <div
                                            class="circle-icon-mini bg-telefono rounded-circle d-middle-center my-auto"
                                            >
                                                <i class="icon-cellphone op-05 f-18" />
                                            </div>
                                            <div class="ml-2">
                                                <p class="f-12">Número de celular</p>
                                                <p class="f-14 f-500">{{ residente.telefono || 'No registra' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
                                        <div class="d-flex">
                                            <div
                                            class="circle-icon-mini bg-email rounded-circle d-middle-center my-auto"
                                            >
                                                <i class="icon-email op-05 f-14 pr-1" />
                                            </div>
                                            <div class="ml-2">
                                                <p class="f-12">Correo electrónico</p>
                                                <p class="f-14 f-500">{{ residente.correo || 'No registra' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
                                        <div class="d-flex">
                                            <div
                                            class="circle-icon-mini bg-cumpleanos rounded-circle d-middle-center my-auto"
                                            >
                                                <i class="icon-birthday op-05 f-18" />
                                            </div>
                                            <div class="ml-2">
                                                <p class="f-12">Fecha de nacimiento</p>
                                                <p class="f-14 f-500">{{ formatearFecha(residente.nacimiento) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
                                        <div class="d-flex">
                                            <div
                                            class="circle-icon-mini bg-genero rounded-circle d-middle-center my-auto"
                                            >
                                                <i class="icon-gender op-05" />
                                            </div>
                                            <div class="ml-2">
                                                <p class="f-12">Género</p>
                                                <p class="f-14 f-500">{{ genero(residente.genero) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="f-14 text-86 mt-4">Registado: {{ formatearFecha(residente.created_at) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-5 col-lg-3 my-4">
                        <div class="row">
                            <div class="col-12 text-center my-2">
                                <p class="f-16 f-500">Datos adicionales</p>
                            </div>
                            <div v-if="residente.mascotas.length > 0" class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 border-bottom">
                                <div v-for="(mascota, k) in residente.mascotas" :key="k" class="d-flex my-2">
                                    <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                        <i class="icon-paw op-05 f-19" />
                                    </div>
                                    <div class="ml-2">
                                        <p class="f-14 f-500">{{ mascota.nombre }}</p>
                                        <p class="f-12">{{ raza(mascota.tipo, mascota.otro) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="residente.vehiculos.length > 0" class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 border-bottom">
                                <div v-for="(vehiculo, v) in residente.vehiculos" :key="v" class="d-flex my-2">
                                    <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                        <i class="icon-car op-05 f-22" />
                                    </div>
                                    <div class="ml-2">
                                        <p class="f-14 f-500">
                                            {{ tipoVehiculo(vehiculo.tipo) }}: {{ vehiculo.marca }} /
                                            {{ vehiculo.color }}
                                        </p>
                                        <p class="f-12">{{ vehiculo.placas }}</p>
                                        <p class="f-12 mb-2">{{ vehiculo.comentario }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 border-bottom">
                                <div class="d-flex my-2">
                                    <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                        <i class="icon-broom op-05 f-24" />
                                    </div>
                                    <div class="ml-2">
                                        <p class="f-14 f-500">Servicio domestico</p>
                                        <p class="f-12">{{ servicioDomestico(residente.servicio_domestico) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center my-2">
                                <p class="f-16 f-500">Residentes en la vivienda</p>
                            </div>
                            <div v-if="residente.residentes.length > 0"
                                 class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2"
                            >
                                <div v-for="(r, key) in residente.residentes" :key="key" class="d-flex my-2">
                                    <div class="circle-icon-mini bg-db rounded-circle d-middle-center my-auto">
                                        <i class="icon-account-checked-outline op-05 f-18" />
                                    </div>
                                    <div class="ml-2">
                                        <p class="f-14 f-500">{{ r.nombre }} ( {{ r.edad }} años)</p>
                                        <p class="f-12">{{ r.parentesco }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- con quienes vive -->
                <div class="row mt-5">
                    <div class="col-12">
                        <p class="f-17 f-500">Con quien vive</p>
                    </div>
                </div>
                <!-- listado  -->
                <div class="row mt-3">
                    <div v-for="(companero, index) in residente.companeros" :key="index" class="col-auto mb-2">
                        <card-residente :residente="companero" class="pb-2 pt-3 px-3" @actualizar="getUser" />
                        <!-- <div class="card-residente border br-8 pb-2 pt-3 px-3">
                            <div class="row">
                                <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                                    <div class="like-img bg2 br-50 d-middle-center f-30 mx-auto">
                                        <i class="icon-account-outline op-05" />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-9 col-md-9 col-lg-9 text-center text-sm-left pl-0">
                                    <p class="f-500 f-20">{{ companero.nombre }} {{ companero.apellido }}</p>
                                    <p class="f-14 text-86">Residente - Vivienda {{ tipoResidente(companero.arriendo) }}</p>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-sm-3 col-md-3 col-lg-3 d-none d-sm-block"> </div>
                                <div class="col-sm-9 col-md-9 col-lg-9 pl-lg-0 pl-sm-0">
                                    <div class="d-flex justify-content-center justify-content-sm-start">
                                        <div class="d-flex d-middle">
                                            <div class="like-img-mini bg-phone br-50 d-middle-center f-17 mr-2">
                                                <i class="icon-cellphone op-05" />
                                            </div>
                                            <div class="lh-18">
                                                <p class="f-12 text-86">Número de celular</p>
                                                <p class="f-500 f-14">{{ companero.telefono }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 pr-0 custom-scroll overflow-auto" style="height:calc(100vh - 57px);">
                <chat-right />
            </div>
        </div>
        <!-- chat  -->

        <!-- partials -->
        <modal-eliminar ref="abrirEliminarResidente" titulo="Eliminar residente" adicional="Rechazar"
                        @eliminar="eliminarResidente"
        >
            <p class="text-center f-500 f-15">¿Desea eliminar a {{ residente.nombre }}?</p>
            <p class="text-center f-15">No podrá acceder a Mi Conjunto y todo registro será borrado del sistema</p>
        </modal-eliminar>
    </section>
</template>
<script>
import Viviendas from "@/services/viviendas"
import Usuarios from '~/services/usuarios'
import Propietarios from '~/services/propietarios'

export default {
    data(){
        return {
            inputChat: '',
            residente: {},
        }
    },
    mounted(){
        this.getUser()
    },
    methods: {
        modalEliminarResidente(){
            this.$refs.abrirEliminarResidente.toggle()
        },
        async getUser(){
            try {
                let id_residente = this.$route.params.id_residente

                const { data } = await Viviendas.getUserPorId({ id_residente })
                this.residente = data.data
            } catch (error){
                this.errorCatch(error)
            }
        },
        genero(genero){
            switch (genero){
            case 2:
                return "Masculino"
            case 3:
                return "Femenino"
            default:
                return "Otro"
            }
        },
        tipoResidente(tipo){
            switch (tipo){
            case 1:
                return "en arriendo"
            case 2:
                return "propia"
            default:
                break;
            }
        },
        editarResidente(){
            if (this.residente.tipo == 5) return this.$router.push({ name: 'propietarios.editar', params: { idPropietario: this.residente.id } })
            this.$router.push({ name: 'viviendas.guardar.residente.id', params: { id_residente: this.residente.id } })
        },
        async eliminarResidente(){
            try {
                if (this.residente.tipo == 5){
                    const { data } = await Propietarios.deletePropietario(this.residente.id)

                    this.notificacion('', 'Residente eliminado correctamente', 'success')
                    this.$router.push({ name: "propietarios" })
                    this.$refs.abrirEliminarResidente.toggle()
                    return
                }
                const { data } = await Usuarios.eliminarResidente(this.residente.id)

                this.notificacion('', 'Residente eliminado correctamente', 'success')
                this.$router.push({ name: "viviendas.residentes" })
                this.$refs.abrirEliminarResidente.toggle()
            } catch (error){
                this.errorCatch(error)
            }
        },
        raza(tipo, otro){
            switch (tipo){
            case 1:
                return "Perro"
            case 2:
                return "Gato"
            default:
                return otro
            }
        },
        tipoVehiculo(tipo){
            switch (tipo){
            case 1:
                return "Moto"
            case 2:
                return "Carro"
            default:
                return "Camioneta"
            }
        },
        servicioDomestico(tipo){
            switch (tipo){
            case 0:
                return "No hay"
            case 1:
                return "Esporádico"
            case 2:
                return "Medio Tiempo"
            case 3:
                return "Tiempo Completo"
            default:
                return "No hay"
            }
        },
        residentePropietario(residente){
            if (residente.tipo === 1 && residente.propietario === 1){
                return "Residente - Propietario"
            } else if (residente.tipo === 5){
                return "Propietario"
            }else {
                return "Residente"
            }
        }
    }
}
</script>
<style lang="scss" scoped>
$bg-chat: #fff;
$bg-bubble-left: #f5f5f5;
$bg-bubble-right: #C8F5E6;
$font-size-bubble: 13px;
$color-text-bubble: #000;

.viviendas-ver-residente {
	height: calc(100vh - 55px);

	.circle-icon {
		min-width: 52px;
		max-width: 52px;
		min-height: 52px;
	}

	.circle-icon-mini {
		min-width: 32px;
		max-width: 32px;
		min-height: 32px;
		max-height: 32px;
	}

	.chat-left {
		background: $bg-chat 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000014;
		border: 1px solid #dbdbdb;
		height: 100%;
		position: relative;

		.chat-bubble {
			border: 1px solid #F8FCFF;
			font-size: $font-size-bubble;
			color: $color-text-bubble;

			&-left {
				background: $bg-bubble-left;
			}

			&-right {
				background: $bg-bubble-right;
			}

			&-arrow-left {
				@extend .arrows-chat;
				left: -10px;
				border-color: $bg-bubble-left #00000000 #00000000 transparent;
			}

			&-arrow-right {
				@extend .arrows-chat;
				right: -10px;
				border-color: $bg-bubble-right #00000000 #00000000 transparent;
			}

			.arrows-chat {
				border-style: solid;
				border-width: 10px;
				top: 0px;
				height: 0;
				position: absolute;
				width: 0;
			}
		}

		.chat-input {
			.icon-add-img {
				position: absolute;
				z-index: 1;
				right: 9px;
				top: 11px;
				font-size: 20px;
				opacity: 0.5;
				cursor: pointer;
			}

			.activo {
				color: #109881;
			}
		}
	}
}
</style>